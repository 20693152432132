export const traducao = {
    "apresentacao.en-US": [
      "Hello, I'm a Full Stack developer",
      "This will be my portfolio, but I'm still working on it.",
      "Check back soon to see the progress. Thanks, see you soon!",
    ],
    "apresentacao.pt-BR": [
      "Olá, sou um desenvolvedor Full Stack",
      "Este será meu portfólio, mas ainda estou trabalhando nele.",
      "Volte em breve para ver o progresso. Obrigado, até breve!",
    ],
    "ola.pt-BR": ["Olá!"],
    "ola.en-US": ["Hello there!"],
  };
  