import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Avatar from "@mui/material/Avatar";
import ProfileFoto from "../assets/profilephoto.png";
import BrazilFlag from "../assets/brazil-flag.svg";
import UsaFlag from "../assets/usa-flag.svg";
import { ReactTyped } from "react-typed";
import * as React from "react";
import { styled } from "@mui/material/styles";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import { useState } from "react";
import { traducao } from "../shared/Translate";
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import GitHubIcon from '@mui/icons-material/GitHub'; 

const MaterialUISwitch = styled(Switch)(({ theme }) => ({
  width: 62,
  height: 34,
  padding: 7,
  "& .MuiSwitch-switchBase": {
    margin: 1,
    padding: 0,
    transform: "translateX(6px)",
    "&.Mui-checked": {
      color: "#fff",
      transform: "translateX(22px)",
      "& .MuiSwitch-thumb:before": {
        backgroundImage: `url(${BrazilFlag})`,
      },
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: "#aab4be",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    backgroundColor: "#001e3c",
    width: 32,
    height: 32,
    "&::before": {
      content: "''",
      position: "absolute",
      width: "100%",
      height: "100%",
      left: 0,
      top: 0,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      backgroundImage: `url(${UsaFlag})`,
    },
  },
  "& .MuiSwitch-track": {
    opacity: 1,
    backgroundColor: "#aab4be",
    borderRadius: 20 / 2,
  },
}));

function Home() {
  const [languagePtBr, setChecked] = useState(true);

  const handleSwitchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100vw",
        height: "100vh",
        background: "linear-gradient(to bottom, #0f0f0f, #1a1a1a)",
        color: "white",
        p: { xs: 2, md: 5 },
      }}
    >
      <Paper
        elevation={3}
        sx={{
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "transparent",
          flexDirection: { xs: "column", md: "row" }, // Column on small screens
        }}
      >
        <Box
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
            p: { xs: 1, md: 2 },
            alignItems: "center",
            flexDirection: { xs: "column", md: "row" }, // Responsive direction
          }}
        >
          <div style={{ textAlign: "center" }}>
            <h1
              style={{
                fontFamily: "Montserrat",
                fontSize: "2rem" ,
                color: "white",
                marginLeft: "2em",
              }}
            >
              Rodrigo Nobre
            </h1>
            <div
              style={{
                display: "flex",
                gap: "1em",
                justifyContent: "center",
                marginTop: "0.5em",
                marginLeft: '4em'
              }}
            >
              <a
                href="https://github.com/rnobrelima"
                target="_blank"
                rel="noopener noreferrer"
              >
                <GitHubIcon fontSize="large" />
              </a>
              <a
                href="https://www.linkedin.com/in/rnobrelima"
                target="_blank"
                rel="noopener noreferrer"
              >
                <LinkedInIcon fontSize="large" />
              </a>
            </div>
          </div>
          <FormControlLabel
            control={
              <MaterialUISwitch
                sx={{ m: 1 }}
                defaultChecked
                onChange={handleSwitchChange}
              />
            }
            sx={{ color: "white" }}
            label=""
          />
        </Box>

        <Box
          sx={{
            textAlign: "center",
            mt: { xs: 4, md: 8 },
            maxWidth: { xs: "90%", md: "800px" },
            width: '80%',
          }}
        >
          <Avatar
            alt="Rodrigo Nobre"
            sx={{
              width: { xs: "5em", md: "12em" },
              height: { xs: "5em", md: "12em" },
              margin: 'auto',
            }}
            src={ProfileFoto}
          />
          <h2
            style={{
              fontFamily: "Montserrat",
              fontSize: "2rem" ,
              color: "white",
              marginTop: "1em",
            }}
          >
            {languagePtBr ? traducao["ola.pt-BR"] : traducao["ola.en-US"]}
          </h2>
          <p
            style={{
              fontSize: "1rem",
              maxWidth: "500px",
              margin: "auto",
              color: "white",
            }}
          >
            <ReactTyped
              strings={
                languagePtBr
                  ? traducao["apresentacao.pt-BR"]
                  : traducao["apresentacao.en-US"]
              }
              typeSpeed={50}
              startDelay={2000}
              loop
            />
          </p>
        </Box>
      </Paper>
    </Box>
  );
}

export default Home;
